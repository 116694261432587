import {
  HiOutlineClipboardDocumentList,
  HiOutlineEnvelope,
  HiOutlineFolderOpen,
  HiOutlineStar,
  HiOutlineUser,
} from "react-icons/hi2";
import { TbArrowsDiff } from "react-icons/tb";

import type { SidebarNavigationItem } from "~/components/SidebarNavigation";

export const disabledCategories = ["example-category-slug"];

export const categoriesWithRanges = ["systemes-de-fixation"];

export const categoriesWithBrandsPage = ["systemes-de-fixation"];

export const categoriesWithoutBrandsFilters = [
  "kits-solaires",
  "ombrieres-et-carports",
  "cables-et-connectiques",
  "coffrets-electriques",
];

export const categoriesNavGroups = [
  {
    label: "Tous nos kits",
    childrenSlugs: ["kits-solaires", "kits-plug-and-play", "ombrieres-et-carports"],
  },
  {
    label: "Matériel électrique",
    childrenSlugs: ["coffrets-electriques", "cables-et-connectiques", "e-mobilite"],
  },
];

export const headerSecondaryNavItems: SidebarNavigationItem[] = [
  {
    label: "Ressources",
    children: [
      {
        label: "Glossaire",
        to: "/glossaire",
      },
      {
        label: "FAQ",
        to: "/faq",
      },
    ],
  },
  {
    label: "Qui sommes-nous",
    to: "/a-propos/qui-sommes-nous",
  },
  {
    label: "Nos partenaires",
    to: "/nos-partenaires",
  },
  {
    label: "Nos expertises",
    to: "/a-propos/nos-expertises",
  },
  {
    label: "Contactez-nous",
    to: "/nous-contacter",
    icon: HiOutlineEnvelope,
  },
];

export const headerMobileSecondaryNavItems = [
  {
    label: "Glossaire",
    to: "/glossaire",
  },
  {
    label: "FAQ",
    to: "/faq",
  },
  {
    label: "Qui sommes-nous",
    to: "/a-propos/qui-sommes-nous",
  },
  {
    label: "Nos partenaires",
    to: "/nos-partenaires",
  },
  {
    label: "Contactez-nous",
    to: "/nous-contacter",
  },
];

export const accountNavItems: SidebarNavigationItem[] = [
  {
    label: "Mon profil",
    to: "/account/profile",
    icon: HiOutlineUser,
    children: [
      {
        label: "Mes informations",
        to: "/account/profile/informations",
      },
      {
        label: "Mes adresses",
        to: "/account/profile/addresses",
      },
    ],
  },
  {
    label: "Mes affaires et devis",
    to: "/account/carts",
    icon: HiOutlineClipboardDocumentList,
  },
  {
    label: "Mes documents",
    to: "/account/documents",
    icon: HiOutlineFolderOpen,
    children: [
      {
        label: "Mes commandes",
        to: "/account/documents/orders",
      },
      {
        label: "Mes bons de livraison",
        to: "/account/documents/delivery-notes",
      },
      {
        label: "Mes factures",
        to: "/account/documents/invoices",
      },
      {
        label: "Mes avoirs",
        to: "/account/documents/credits",
      },
    ],
  },
  {
    label: "Mes favoris",
    to: "/account/favorites",
    icon: HiOutlineStar,
  },
  {
    label: "Mon comparateur",
    to: "/account/comparator",
    icon: TbArrowsDiff,
  },
];

export const footerServicesNavItems = [];

export const footerSecondaryNavItems = [
  { label: "Mentions légales", slug: "/a-propos/mentions-legales" },
  { label: "CGV", slug: "/a-propos/conditions-generales-de-vente" },
  { label: "Nous contacter", slug: "/nous-contacter" },
  { label: "Plan du site", slug: "/plan-du-site" },
  // { label: "Préférences des cookies", slug: "/cookies" },
  { label: "POwR.group", slug: "https://www.powr.group/" },
];

export const administrationNavItems: SidebarNavigationItem[] = [
  {
    label: "Contenu éditorial",
    to: "/admin/pages",
    children: [
      {
        label: "Pages",
        to: "/admin/pages",
      },
    ],
  },
  {
    label: "Contenu commercial",
    to: "/admin/slider",
    children: [
      {
        label: "Slider page d'accueil",
        to: "/admin/slider",
      },
    ],
  },
  {
    label: "Outils et services",
    to: "/admin/tools-and-services/thumbnails",
    children: [
      {
        label: `Vignettes`,
        to: "/admin/tools-and-services/thumbnails",
      },
      {
        label: `Page "Assurance"`,
        to: "/admin/tools-and-services/insurance",
      },
    ],
  },
];

export const categoriesDisabledInSiteMap = ["confidentialite"];

export const siteMapNavItems = [];
